<template>
  <v-expansion-panels
    v-if="Array.isArray(attachments) && attachments.length > 0"
    flat
  >
    <v-expansion-panel class="supportInfo__attachment">
      <v-expansion-panel-header class="supportInfo__attachmentHeader">
        <div class="d-flex align-center">
          <v-icon class="mr-2">
            mdi-email-outline
          </v-icon>
          <h4
            v-t="'support.fields.email'"
            class="mr-2"
          />
          <v-progress-circular
            v-if="attachmentsLoading === loadingId"
            :size="20"
            :width="3"
            indeterminate
          />
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="px-0">
        <div
          v-for="{ fileName, url } in emails"
          :key="url"
          class="px-2"
        >
          <a
            target="_blank"
            rel="noopener"
            @click="downloadAttachment(fileName, url)"
          >{{ fileName }}</a>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel class="supportInfo__attachment">
      <v-expansion-panel-header class="supportInfo__attachmentHeader">
        <div class="d-flex align-center">
          <v-icon class="mr-2">
            mdi-attachment
          </v-icon>
          <h4
            v-t="'support.fields.attachment'"
            class="mr-2"
          />
          <v-progress-circular
            v-if="attachmentsLoading === loadingId"
            :size="20"
            :width="3"
            indeterminate
          />
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="px-0">
        <div
          v-for="{ fileName, url } in files"
          :key="url"
          class="px-2"
        >
          <a
            target="_blank"
            rel="noopener"
            @click="downloadAttachment(fileName, url)"
          >{{ fileName }}</a>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    attachments: {
      type: Array,
      required: true
    },
    loadingId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('support', ['attachmentsLoading']),
    files () {
      return this.attachments.filter(a => !a.fileName.endsWith('.eml') && !a.fileName.endsWith('.msg'))
    },
    emails () {
      return this.attachments.filter(a => a.fileName.endsWith('.eml') || a.fileName.endsWith('.msg'))
    }
  },
  methods: {
    ...mapActions('support', ['downloadTicketAttachment']),
    downloadAttachment (fileName, url) {
      this.downloadTicketAttachment({ fileName, url, loadingId: this.loadingId })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-header__icon {
  flex: 1 1 100%;
}

::v-deep .supportComment__attachment {
  padding-bottom: $spacer * 2;
}

::v-deep .supportComment__attachmentHeader,
::v-deep .v-expansion-panel--active>.v-expansion-panel-header {
  padding: 0;
  min-height: auto;
}
</style>
